<template>
  <div class="app-container">
    <div class="config">
      <div class="title">NVR信息</div>
      <div class="config-item">
        <label>设备名称:</label>
        {{ NvrInfo.name }}
      </div>
      <div class="config-item">
        <label>通道数:</label>
        {{ NvrInfo.channelCnt }}
      </div>
      <div class="config-item">
        <label>厂商:</label>
        {{ NvrInfo.vendor }}
      </div>
      <div class="config-item">
        <label>型号:</label>
        {{ NvrInfo.model }}
      </div>
      <div class="config-item">
        <label>盒子MacID:</label>
        {{ NvrInfo.boxMac }}
      </div>
      <div class="config-item">
        <label>盒子端口号:</label>
        {{ NvrInfo.boxPort }}
      </div>
      <div class="config-item">
        <label>MacID:</label>
        {{ NvrInfo.macId }}
      </div>
      <div class="config-item">
        <label>IP地址:</label>
        {{ NvrInfo.ip }}
      </div>
      <div class="config-item">
        <label>最近一次操作时间:</label>
        {{ NvrInfo.updateTime }}
      </div>
    </div>
    <el-divider></el-divider>
    <div class="setting">
      <el-form :inline="true" :model="form" :rules="rules" ref="ruleForm">
        <el-form-item label="设备名称:" prop="name">
          <el-input v-model.trim="form.name" placeholder="填入当前设备名称" :maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="设备账号" prop="uname">
          <el-input v-model="form.uname" auto-complete="off" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="设备密码" prop="pwd">
          <el-input v-model="form.pwd" auto-complete="off" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="厂商" prop="vendor">
          <el-select v-model="form.vendor" placeholder="请选择">
            <el-option v-for="item in vedorOpt" :key="item" :label="item" :value="item" ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button type="primary" @click="handleSave">保存</el-button>
      </div>
    </div>
    <div class="title">通道列表</div>
    <el-button class="newDevice" type="primary" @click="openAddDialog(2)">添加</el-button>
    <el-table :data="bindTableData" class="bindtable" row-key="pkid" v-loading="bindLoading" show-overflow-tooltip>
      <el-table-column type="index" :index="indexTable" min-width="30" label="序号"></el-table-column>
      <el-table-column label="摄像头名称">
        <template slot-scope="scope">
          <el-input v-model.trim="scope.row.name" @focus="lastName=scope.row.name" @blur="updateChannel(scope.row)" placeholder="请输入内容" maxlength="50"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="ip" align="left" show-overflow-tooltip label="IP地址">
        <template slot-scope="scope">
          {{ scope.row.ip || '--'}}
        </template>
      </el-table-column>
      <el-table-column prop="channelId" align="left" show-overflow-tooltip label="通道号"></el-table-column>
      <el-table-column prop="bindTime" align="left" show-overflow-tooltip label="绑定时间"></el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="200">
        <template slot-scope="scope">
          <el-button @click="unbind(scope.row)" class="unbind" type="text" size="small">解绑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-button class="newDevice" type="primary" @click="scanChannelsByNvr(true)">扫描新设备</el-button>
    <el-table
      :data="nobindtable"
      row-key="pkid"
      class="nobindtable"
      v-loading="unbindLoading"
      ref="multipleTable"
      :header-cell-class-name="tableClass"
      show-overflow-tooltip
    >
      <el-table-column type="index" :index="noindexTable" align="right" label="序号"></el-table-column>
      <el-table-column prop="name" align="left" show-overflow-tooltip label="摄像头名称"></el-table-column>
      <el-table-column prop="online" align="center" label="摄像头状态" width="90">
        <template slot-scope="scope">
          {{ scope.row.online === true ? '在线' : '离线' }}
        </template>
      </el-table-column>
      <el-table-column prop="channelId" align="left" show-overflow-tooltip label="通道号">
        <template slot-scope="scope">
          {{ scope.row.channelId || '--'}}
        </template>
      </el-table-column>
      <el-table-column prop="ip" align="left" show-overflow-tooltip label="IP地址">
        <template slot-scope="scope">
          {{ scope.row.ip || '--'}}
        </template>
      </el-table-column>
      <el-table-column prop="snapshot" align="center" label="截图">
        <template slot-scope="scope">
          <img :src="scope.row.snapshot" @click="enlargeImg(scope.row.snapshot)" class="screenshot" />
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="200">
        <template slot-scope="scope">
          <el-button @click="openAddDialog(1,scope.row)" class="unbind" type="text" size="small">绑定</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="allSelect">
      <div class="footer">
        <el-button @click="back">上一步</el-button>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 20]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog :visible.sync="imgDialog" class="imgDialog" width="800px" title="预览">
      <img :src="bigImgUrl" class="bigImgUrl" />
    </el-dialog>

    <el-dialog :title="type===1?'绑定通道':'手动添加通道'" width="400px" :visible.sync="bindDialogVisible" :close-on-click-modal="false" class="bindDialog">
      <el-form :model="addForm" :rules="addRules" ref="addForm" label-width="100px">
        <el-form-item label="摄像头名称" prop="name">
          <el-input v-model.trim="addForm.name" auto-complete="off" maxlength="50" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="通道号" prop="channelId">
          <el-input :disabled="type===1" v-model.trim="addForm.channelId" auto-complete="off" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="MacID" prop="macId">
          <el-input v-model.trim="addForm.macId" auto-complete="off" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="IP地址" prop="ip">
          <el-input v-model.trim="addForm.ip" auto-complete="off" placeholder="请填写"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="bindDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="bind">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getNvrInfo, updateNvrInfo, scanChannelsByNvr, bindToNvr, unbindFromNvr, updateChannel, isChannelIdExist } from 'src/api/nvr'

export default {
  data() {
    var validateChannel = async(rule, value, callback) => {
      const {data} = await isChannelIdExist({nvrMac:this.macId,channelId:value})
      console.log(999,data)
      if (value === '') {
        callback(new Error('请填写后点击确认'))
      } else if (data) {
        callback(new Error('通道号已存在!'))
      } else {
        callback()
      }
    }
    return {
      NvrInfo: {},
      vedorOpt: ['DA_HUA', 'JVS', 'HIKVISION'],
      form: {
        name: '',
        uname: '',
        pwd: '',
        vendor: '',
      },
      rules: {
        name: [{ required: true, message: '请填写后点击确认', trigger: 'blur' }],
        uname: [{ required: true, message: '请填写后点击确认', trigger: 'blur' }],
        pwd: [{ required: true, message: '请填写后点击确认', trigger: 'blur' }],
        vendor: [{ required: true, message: '请选择后点击确认', trigger: 'change' }],
      },
      bindTableData: [],
      newTable: [],
      bindLoading: false,
      unbindLoading: false,
      currentPage: 1,
      pageSize: 5,
      total: 0,
      imgDialog: false,
      bigImgUrl: '',

      bindDialogVisible: false,
      addForm: {
        name: '',
        ip: '',
        macId: '',
        channelId: '',
      },
      addRules: {
        name: [{ required: true, message: '请填写后点击确认', trigger: 'blur' }],
        channelId: [{ required: true, validator: validateChannel, trigger: 'blur' }],
      },
      productId: '',
      macId: '',
      type:1,
      lastName:''
    }
  },
  computed: {
    nobindtable() {
      return this.newTable
        .slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    },
  },
  mounted() {
    this.getInfo()
    this.scanChannelsByNvr()
  },
  methods: {
    getInfo() {
      const { productId, macId } = this.$route.query
      this.macId = macId
      this.productId = productId
      const params = {
        productId,
        macId,
      }
      getNvrInfo(params).then((res) => {
        this.NvrInfo = res.data || {}
        this.form.name = res.data?.name
        this.form.uname = res.data?.uname
        this.form.pwd = res.data?.pwd
        this.form.vendor = res.data?.vendor
      })
    },
    back() {
      this.$router.go(-1)
    },
    handleSave() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.updateNvrInfo()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async updateNvrInfo() {
      const params = {
        macId: this.macId,
        name: this.form.name,
        vendor: this.form.vendor,
        uname: this.form.uname,
        pwd: this.form.pwd,
      }
      const res = await updateNvrInfo(params)
      if (res && res.status === 0) {
        this.$router.go(-1)
      }
    },
    indexTable(index) {
      if (typeof index === 'undefined') {
        return 0
      }
      return index + 1
    },
    noindexTable(index) {
      if (typeof index === 'undefined'){
        return 0
      } 
      return (this.currentPage - 1) * this.pageSize + index + 1
    },
    tableClass({ columnIndex }) {
      if (columnIndex === 0) {
        return 'hideLabel'
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    unbind(row) {
      console.log(row)
      this.$confirm(`是否解绑`, '提示').then(() => {
        this.unbindFromNvr(row)
      })
    },
    unbindFromNvr(row) {
      let data = {
        nvrMac: this.macId,
        channelId: row.channelId,
      }
      unbindFromNvr(data).then((res) => {
        if (res && res.status === 0) {
          this.$alert('解绑成功', '解绑提示', {
            confirmButtonText: '确定',
          })
          this.scanChannelsByNvr()
        }
      })
    },
    scanChannelsByNvr(msg) {
      this.bindLoading = true
      this.unbindLoading = true
      scanChannelsByNvr({
        nvrMac: this.macId,
      })
        .then((res) => {
          if (res && res.status === 0) {
            const hasBind = res.data.hasBind ? res.data.hasBind : []
            for (const v of hasBind) {
              v.boxMAC = v.boxMAC || ''
            }
            this.bindTableData = hasBind
            this.newTable = res.data.noBind
            for (const v of this.newTable) {
              v.boxMAC = v.boxMAC || ''
            }
            this.total = this.newTable.length
            if (!msg) return
            this.$message({
              message: '扫描成功',
              type: 'success',
            })
          }
        })
        .finally(() => {
          this.bindLoading = false
          this.unbindLoading = false
        })
    },
    resetForm() {
      this.$refs.addForm.resetFields()
    },
    openAddDialog(type, row) {
      this.type = type

      this.bindDialogVisible = true
      this.$nextTick(() => {
        this.resetForm()
        if (type === 1) {
          this.addForm.ip = row.ip
          this.addForm.macId = row.macId
          this.addForm.channelId = row.channelId
        }
        
      })
    },
    bind() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.bindToNvr()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async bindToNvr() {
      const params = {
        nvrMac: this.macId,
        macId: this.addForm.macId,
        name: this.addForm.name,
        ip: this.addForm.ip,
        channelId: this.addForm.channelId,
      }
      const res = await bindToNvr(params)
      if (res && res.status === 0) {
          this.$alert('已绑定NVR列表', '绑定提示', {
            confirmButtonText: '确定',
          })
          this.bindDialogVisible = false
          this.scanChannelsByNvr()
        }
    },
    enlargeImg(url) {
      this.imgDialog = true
      this.bigImgUrl = url
    },
    async updateChannel(row) {
      if(!row.name){
        return row.name = this.lastName
      }
      const res = await updateChannel({
        nvrMac:this.macId,
        channelId: row.channelId,
        name: row.name
      })
      if(res?.status===0) {
        this.$message({
          message: '修改成功',
          type: 'success',
        })
      }
    }
  },
}
</script>

<style lang="less" scoped>
dl,
dt,
dd {
  margin: revert;
}
.screenshot {
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.imgDialog .bigImgUrl {
  width: 760px;
  height: 600px;
  vertical-align: top;
}
.app-container {
  min-height: calc(100vh - 188px);
  position: relative;
  padding-bottom: 48px;

  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .header-info {
    display: flex;

    .left {
      div {
        margin-bottom: 10px;
      }
    }

    .right {
      margin-left: 20px;
    }
  }

  .config {
    .config-item {
      display: inline-block;
      margin-right: 30px;
      margin-bottom: 10px;
      min-width: 160px;
    }
  }

  .setting {
    margin-top: 20px;

    /deep/ .el-form-item {
      margin-right: 30px;
    }
  }
  .bindtable,
  .nobindtable,
  .allSelect {
    margin-bottom: 20px;
  }
  .allSelect {
    display: flex;
    justify-content: space-between;
  }
  .el-button {
    font-size: 12px;
  }
  .footer{
    margin-bottom: 10px;
  }
}
</style>
